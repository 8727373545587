import type { IArrayOption } from '@/interface/common';
import type { IProjectValue, IQuestionOptions, IQuestionValue, ISkipValue } from '@/interface/admin/question';


export namespace QUESTION {
    export const enum SVY_STATUS_TYPE {
        HOLD = 'HOLD',
        OPEN = 'OPEN',
        STOP = 'STOP',
        CANCEL = 'CANCEL',
        END = 'END',
    }

    /**
     * 설문생성 타입
     */
    export enum CREATE_TYPE {
        PMI_PANEL = 1, // PMI 패널조사
        LIST = 2, // LIST 조사
    }

    /**
     * 링크 상태
     */
    export enum LINK_STATUS {
        DEFAULT = -1, //링크 생성 전
        TEST = 0, // 테스트 링크 생성
        REAL = 1, // 링크 확정 (링크 확정 후에는 설문 수정 불가능 하도록 적용)
        PAID = 2, // 패널 조사인 경우 결제된 상태
        REJECT = 3, // 패널 조사인 경우 ADMIN이 조사에 대해 거절한 상태
        REFUND = 4, // 패널 조사인 경우 ADMIN이 환불해준 상태

        REVIEW_HOLD = 201, // 검수 대기
        REVIEW = 202, // 검수 중
        REVIEW_DONE = 203, // 검수 완료

        FW_START = 101, // 실사가 시작된 상태
        FW_END = 102, // 실사 종료
        FW_PAUSE = 103, // 실사 일시 중단
        FW_CANCEL = 104, // 실사 취소
        FW_SHUT_DOWN = 105, // 실사 강제 종료
    }

    export const enum EVENT_FUNCTION {
        MAKE_SET_DATA_NAME = 'makeSetDataName',
        MAKE_QUESTION_RELOAD = 'makeQuestionReload',
        MAKE_QUESTION_EDIT = 'makeQuestionEdit',
        MAKE_QUESTION_REMOVE = 'makeQuestionRemove',
        LOADING_START = 'loadingStart',
        LOADING_END = 'loadingEnd',
        MESSAGE = 'message',
        PDF_DOWNLOAD = 'down-pdf',
        CHANGE_EDIT_MODE = 'changeEditMode',
    }

    export const enum RANDOM_TYPES {
        NONE = '',
        ALL = 'ALL',
        EXCEPT_ONE = '-1',
        EXCEPT_TWO = '-2',
    }

    export const SVY_STATUS_OPTION: IArrayOption[] = [
        { text: '진행 준비', value: SVY_STATUS_TYPE.HOLD },
        { text: '조사 진행', value: SVY_STATUS_TYPE.OPEN },
        { text: '일시 중단', value: SVY_STATUS_TYPE.STOP },
        { text: '취소', value: SVY_STATUS_TYPE.CANCEL },
        { text: '조사 종료', value: SVY_STATUS_TYPE.END },
    ];

    export const enum ANSWERS {
        ETC_KEY = '9997',
        ETC_TEXT = '기타',
        NOT_KEY = '9998',
        NOT_TEXT = '없음',
        NONE_COMM_KEY = '9999',
        NONE_COMM_TEXT = '의견 없음',
    }

    export const enum CHILD_TYPE {
        SHOW = 'show',
        HIDE = 'hide',
    }

    export const enum TEXT_REG {
        KOR = '[가-힣]',
        KOR_CONSONANT = '[ㄱ-ㅎㅏ-ㅣ]',
        ENG = '[a-zA-Z]',
        NUM = '[0-9]',
        BLANK = '[\\s]',
        SPECIAL_CHAR = '[\\{\\}\\[\\]\\/?.,;:|\\)*~`!^\\-_+<>@\\#$%&\\\\\\=\\(\\\'\\"]',
    }

    export const enum QUESTION_TYPES {
        RADIO = 'RADIO',
        RADIOSET = 'RADIOSET',
        RADIOSETS = 'RADIOSETS',
        CHECK = 'CHECK',
        CHECKSETS = 'CHECKSETS',
        GRADE_CLICK = 'GRADE_CLICK',
        TEXTAREA = 'TEXTAREA',
        MULTI_TEXT = 'MULTI_TEXT',
        TITLE = 'TITLE',
        DESC = 'DESC',
        CHILD = 'CHILD',
        RECODE_RANGE = 'RECODE_RANGE',
        QUOTA = 'QUOTA',
        RECODE_EXACT = 'RECODE_EXACT',
        COMPLETE = 'COMPLETE',
    }

    export const enum QUESTION_MOVE_TYPE {
        PREV = 'prev',
        APPEND = 'append',
    }

    export const QUESTION_MOVE_OPTION: IArrayOption[] = [
        { text: '뒤로', value: QUESTION_MOVE_TYPE.APPEND },
        { text: '앞으로', value: QUESTION_MOVE_TYPE.PREV },
    ];

    export const QUESTION_TYPES_LIST: IQuestionOptions[] = [
        {
            text: '단일 선택형',
            value: QUESTION_TYPES.RADIO,
            icons: require('@/assets/images/icons/make/radio.svg'),
            desc: '한개의 보기를 선택하는 문항입니다.',
        },
        {
            text: '복수 선택형',
            value: QUESTION_TYPES.CHECK,
            icons: require('@/assets/images/icons/make/check.svg'),
            desc: '여러개의 보기를 선택할 수 있는 문항입니다.',
        },
        {
            icons: require('@/assets/images/icons/make/example.svg'),
            text: '보기 가져오기',
            value: QUESTION_TYPES.CHILD,
        },
        {
            text: '척도형',
            value: QUESTION_TYPES.RADIOSET,
            icons: require('@/assets/images/icons/make/radioset.svg'),
            desc: '척도형 문항입니다.',
        },
        {
            text: '순위형',
            value: QUESTION_TYPES.GRADE_CLICK,
            icons: require('@/assets/images/icons/make/grade_click.svg'),
            desc: '순위를 클릭해서 입력할 수 있는 문항입니다.',
        },
        {
            text: '행렬형',
            value: QUESTION_TYPES.RADIOSETS,
            icons: require('@/assets/images/icons/make/radiosets.svg'),
            desc: '여러 항목에 응답할 수 있는 척도형 문항입니다.',
        },
        {
            text: '주관식 단답형',
            value: QUESTION_TYPES.MULTI_TEXT,
            icons: require('@/assets/images/icons/make/multi_text.svg'),
            desc: '여러개의 입력값을 응답받을 수 있는 문항입니다.',
        },
        {
            text: '주관식 장문형',
            value: QUESTION_TYPES.TEXTAREA,
            icons: require('@/assets/images/icons/make/textarea.svg'),
            desc: '여러줄의 주관식 입력을 받을 수 있는 문항입니다.',
        },
        {
            text: '복수 행렬형',
            value: QUESTION_TYPES.CHECKSETS,
            icons: require('@/assets/images/icons/make/checksets.svg'),
            desc: '여러 항목을 복수로 응답할 수 있는 문항입니다.',
        },
        {
            text: '설문 제목',
            value: QUESTION_TYPES.TITLE,
            icons: require('@/assets/images/icons/make/title.svg'),
            desc: '제목입니다.',
        },
        {
            text: '안내문',
            value: QUESTION_TYPES.DESC,
            icons: require('@/assets/images/icons/make/desc.svg'),
            desc: '안내문입니다.',
        },
    ];

    //가려져야 하는 문항 명
    export const HIDE_QUESTIONS: string[] = [
        'SQ1',
        'SQ2',
        'SQ3',
        'AGE',
        'AGE_R',
        'AREA',
        'QUOTA_COUNT',
        'COUNT',
        'QUOTA_SQ1_AGE_R',
        'COMPLETE',
        'COMPLETE2',
        'COMPLETE3',
        'TXT_COMPLETE',
        'TXT_COMPLETE2',
        'TXT_COMPLETE3',
        'OUT',
        'OUT2',
        'OUT3',
        'TXT_OUT',
        'TXT_OUT2',
        'TXT_OUT3',
        'OVER',
        'TXT_OVER',
        'BAD',
        'TXT_BAD',
    ];

    //고정 문항명
    export const FIXED_QUESTIONS: string[] = ['SQ1', 'SQ2', 'SQ3'];
    //결과 문항들
    //export const RESULT_QUESTIONS: string[] = ["COMPLETE", "TXT_COMPLETE", "OUT", "TXT_OUT", "OVER", "TXT_OVER", "BAD", "TXT_BAD"];

    export function tempQuestionData(): IQuestionValue {
        return {
            TYPE: '',
            NAME: '',
            QUESTION: '',
            PAGE_END: false,
            ANSWER_CHECK: false,
            QNUM: '',
            _WARNINGS: [],
            VERSION: '',
        };
    }

    export const defaultSurveyInfo: IProjectValue = {
        LAST_MSG: {
            TXT_COMPLETE: '',
            TXT_OVER: '',
            TXT_OUT: '',
            TXT_BAD: '',
        },
        CONFIG: {
            DEFAULT_FONTSIZE_QUESTION: '',
            DEFAULT_FONTSIZE_ANSWER: '',
            DEFAULT_FONTSIZE_DESC: '',
            ASK: false,
            COMPLETE_JOIN_CLOSE: false,
            DOWNLOAD_FILENAME: '',
            FILE_SERVER: '',
            FONT: '',
            FONTSIZE_CONTROL: false,
            IS_CATI_PROJECT: '',
            JOIN: {
                FILE_DOMAIN: '',
                MAX_WIDTH: '',
                MOBILE_ONLY: '',
                MOBILE_RESTRICT: '',
                BAD_SECOND: '',
            },
            LANG: '',
            LOGO: {
                CLICK_URL: '',
                HEIGHT: '',
                POSITION: '',
                SCALE: '',
                FILENAME: '',
                MIMETYPE: '',
                PATH: '',
                PATHNAME: '',
                SIZE: 0,
                URL: '',
            },
            MULTI_ANSWER_DIVIDER: '',
            NAVI: false,
            OPEN_END_DT: '',
            OPEN_START_DT: '',
            GUIDE_CREATED: false,
            FROM_EMAIL: '',
            QUOTACHECK_ON_REJOIN: false,
            PROGRESS: '',
            SKIN: 'DEFAULT',
            STATUS: '',
            STATUS_CANCEL_MSG: '',
            STATUS_END_MSG: '',
            STATUS_HOLD_MSG: '',
            STATUS_STOP_MSG: '',
            TARGET_COUNT: '',
            TARGET_OVER_MSG: '',
            TEST: {
                MEMO: false,
            },
            VERSION: '',
            JOIN_COUNT: '',
            JOIN_OVER_MSG: '',
            LOOP_DIVIDER: '',
            MULTI_COMMA: false,
            SAVE_NOW: false,
            LINK_STATUS: LINK_STATUS.DEFAULT,
        },
    };

    export function defaultSkipData(): ISkipValue {
        return {
            NAME: '',
            VALUE: '',
            CONDITION: '',
        };
    }

    export function defaultQuestionData(type: QUESTION_TYPES): IQuestionValue {
        const setQuestionData: IQuestionValue = {
            TYPE: '',
            NAME: '',
            QUESTION: '',
            PAGE_END: true,
            QNUM: '',
            CREATE: true,
        };
        switch (type) {
            case QUESTION_TYPES.RADIO:
                setQuestionData.NUM_IN_ROW = '1';
                setQuestionData.ANSWERS = [
                    { C1: '', C2: '', C3: '', K: '1', V: '보기 1', N: '', CREATE: true },
                    { C1: '', C2: '', C3: '', K: '2', V: '보기 2', N: '', CREATE: true },
                ];
                setQuestionData.LEFT_CATEGORY = false;
                setQuestionData.RANDOM = RANDOM_TYPES.NONE;
                //setQuestionData.ETC = false;
                setQuestionData.NONE = false;
                break;
            case QUESTION_TYPES.RADIOSET:
                setQuestionData.KEY_SHOW = false;
                setQuestionData.BACKGROUND = '#dddddd,#dddddd';
                setQuestionData.ANSWERS = [
                    { K: '1', V: '보기 1', N: '', CREATE: true },
                    { K: '2', V: '보기 2', N: '', CREATE: true },
                ];
                setQuestionData.NONE_COMM = false;
                break;
            case QUESTION_TYPES.RADIOSETS:
                setQuestionData.V_WIDTH = '40';
                setQuestionData.BY_CARD = false;
                setQuestionData.BY_LINE = false;
                setQuestionData.BACKGROUND = '#dddddd,#dddddd';
                setQuestionData.MOBILE_UI = true;
                setQuestionData.V = [
                    { K: '1', V: '행 보기 1', C1: '', CREATE: true },
                    { K: '2', V: '행 보기 2', C1: '', CREATE: true },
                ];
                setQuestionData.H = [
                    { K: '1', V: '열 보기 1', C1: '', CREATE: true },
                    { K: '2', V: '열 보기 2', C1: '', CREATE: true },
                ];
                setQuestionData.RANDOM = RANDOM_TYPES.NONE;
                setQuestionData.KEY_SHOW = false;
                break;
            case QUESTION_TYPES.CHECK:
                setQuestionData.NUM_IN_ROW = '1';
                setQuestionData.ANSWERS = [
                    { C1: '', K: '1', V: '보기 1', Y_GO: '', N_GO: '', SINGLE: false, CREATE: true },
                    { C1: '', K: '2', V: '보기 2', Y_GO: '', N_GO: '', SINGLE: false, CREATE: true },
                ];
                setQuestionData.RANDOM = RANDOM_TYPES.NONE;
                setQuestionData.LEFT_CATEGORY = false;
                setQuestionData.MIN_CHECK = '1';
                setQuestionData.MAX_CHECK = '';
                break;
            case QUESTION_TYPES.CHECKSETS:
                setQuestionData.V = [
                    { K: '1', V: '행 보기 1', C1: '', CREATE: true },
                    { K: '2', V: '행 보기 2', C1: '', CREATE: true },
                ];
                setQuestionData.H = [
                    { K: '1', V: '열 보기 1', C1: '', SINGLE: false, CREATE: true },
                    { K: '2', V: '열 보기 2', C1: '', SINGLE: false, CREATE: true },
                ];
                setQuestionData.V_WIDTH = '40';
                setQuestionData.RANDOM = RANDOM_TYPES.NONE;
                setQuestionData.RANDOM_H = RANDOM_TYPES.NONE;
                setQuestionData.BY_CARD = false;
                setQuestionData.MOBILE_UI = true;
                setQuestionData.MIN_CHECK = '1';
                break;
            case QUESTION_TYPES.GRADE_CLICK:
                setQuestionData.NUM_IN_ROW = '1';
                setQuestionData.V = [
                    { C1: '', K: '1', V: '보기 1', SINGLE: false, CREATE: true },
                    { C1: '', K: '2', V: '보기 2', SINGLE: false, CREATE: true },
                    { C1: '', K: '3', V: '보기 3', SINGLE: false, CREATE: true },
                ];
                setQuestionData.MAX_GRADE = '3';
                setQuestionData.MIN_GRADE = '1';
                setQuestionData.V_WIDTH = '40';
                setQuestionData.RANDOM = RANDOM_TYPES.NONE;
                setQuestionData.SELECTED_SHOW = true;
                setQuestionData.LANG_MIN_GRADE = '최소 [0]순위까지 선택해 주세요.';
                break;
            case QUESTION_TYPES.TEXTAREA:
                setQuestionData.VOWEL_DENY = false;
                setQuestionData.KOR_DENY = false;
                setQuestionData.ENG_DENY = false;
                setQuestionData.NUMBER_DENY = false;
                setQuestionData.BLANK_DENY = false;
                setQuestionData.SPE_CHAR_DENY = false;
                setQuestionData.MIN_LENGTH = '2';
                setQuestionData.MAX_LENGTH = '9999';
                break;
            case QUESTION_TYPES.MULTI_TEXT:
                setQuestionData.VOWEL_DENY = false;
                setQuestionData.KOR_DENY = false;
                setQuestionData.ENG_DENY = false;
                setQuestionData.NUMBER_DENY = false;
                setQuestionData.BLANK_DENY = false;
                setQuestionData.SPE_CHAR_DENY = false;
                setQuestionData.MIN_LENGTH = '2';
                setQuestionData.MAX_LENGTH = '9999';
                setQuestionData.MIN_COUNT = '1';
                setQuestionData.MAX_COUNT = '1';
                setQuestionData.V_WIDTH = '200';
                setQuestionData.PASS = '';
                setQuestionData.HEAD = '';
                setQuestionData.TAIL = '';
                break;
            case QUESTION_TYPES.DESC:
                setQuestionData.DATA = [{ HTML: '', ALIGN: 'left' }];
                setQuestionData.NO_BACKGROUND = false;
                setQuestionData.BG = '#e0e0e0';
                setQuestionData.BORDER = '';
                break;
            case QUESTION_TYPES.TITLE:
                setQuestionData.HTML;
                setQuestionData.BG = '#1f8dd6';
                break;
        }
        return setQuestionData;
    }

    export enum TYPE {
        MIN_2_QUESTION = 'min_2',
    }

    export enum MODAL_TYPE {
        MOVE = 'MOVE',
        COPY = 'COPY',
        DELETE = 'DELETE',
        CHECK = 'CHECK',
        CANCEL = 'CANCEL',
        END = 'END',
        SAVE = 'SAVE'
    }
    // MAKE QUESTION PROP
    export type VALUE = Partial<IQuestionValue>;
}
